import forList from "@/mixin/forList";
import {
    searchTypeEnum,
    dateTypeEnum,
    payStateEnum,
    fileStateEnum,
    expireStateEnum,
    actionList
} from "./config";

// 将数字转换为美式表示方式
const formatNumber = function (val) {
    if ((typeof val) !== "number") return "";
    const _val = String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return _val;
}

export default {
    name: "newEoiList",
    noFetchFirst: true,
    mixins: [forList],
    components: {
    },

    data() {
        return {
            searchTypeEnum,       // 模糊查询字段选项
            dateTypeEnum,         // 供查询的日期类型选项
            enumIsReady: false, // 以下几个远程的选项数据是否已经获得
            regionList: [], // 区域选项     name, id
            OrderProcessTypeEnum: [],
            OrderProcessChildTypeEnum: [],
            OrderProcessEnum: [],
            OrderProcessStateEnum: [],

            departmentEnum: [], // 部门小组选项
            OrderStateEnum: [], // 订单状态选项
            payTypeList: [], // 支付方式选项 name, type
            moneyTypeList: [], // 币种选项     name, type
            seasonList: [], // 赛季选项     name, id
            OrderLaterStateEnum: [], // 到期状态选项
            OrderAddStateEnum: [], // 增投类型选项
            payStateEnum,         // 回款状态选项
            fileStateEnum,        // 资料收集状态选项
            expireStateEnum,      // 是否到期选项
            SP_: {
                productName: '',
                searchType: 1,
                searchValue: "",
                searchTimeType: 1,
                searchStartTime: "",
                searchEndTime: "",

                searchRegin: [], // 搜索区域
                searchDept: [], // 搜索部门
                searchMoneyType: [],
                searchMoneyChildType: [],
                searchState: [],
                searchCheckState: [],
                searchPayState: "",
                searchDealStatus: [],
            },
            searchHrefType: "", // 快捷搜索标识：1待提交佣金申请 2需补录状态订单

            countMap: {
                fine: false
            }, // 业绩统计

            showExportPayments: false, // 弹出导出缴费的日期选择窗
            showExplain: false, // 订单状态说明
            products: [],//产品名称
            // 暂未数据':' 请输入产品名称进行搜索
            emptyText: '请输入产品名称进行搜索',
            showTip: false,
            chengjiaozhuangtaiarr: [
                { name: '排队中', value: 1 },
                { name: '备序排队', value: 2 },
                { name: '已锁定额度  ', value: 3 },
            ]
        };
    },

    created() {
        this.auth.save = this.$hasAuthFor("api/order/saveOrder");
        this.auth.detail = this.$hasAuthFor("api/order/queryOrderDetails");
        this.auth.delete = this.$hasAuthFor("api/orderProcess/deleteOrderProcess");
        // this.auth.export = this.$hasAuthFor("api/orderProcess/downOrderProcess");
        this.auth.export = this.$hasAuthFor("api/orderProcess/downloadDueOrderProcess");


        actionList.forEach(action => {
            action.banned = !this.auth[action.name]
        });

        const { params } = this.$route;
        if (Object.keys(params).length) {
            for (let key in params) {
                if (key === "searchHrefType") this.searchHrefType = params[key];
                else if (Object.prototype.hasOwnProperty.call(this.SP_, key))
                    this.SP_[key] = params[key]
            }
            this.showAdvanceSearch = true;
            this.handleSearch();
        }
        else {
            this.fetchData();
        }
        this.fetchEnum()


        this.getPorget()
    },

    methods: {
        toAuditEoi(data) {
            this.$router.push({
                path: `/core/eoi/edit?id=${data.orderId}`,
            });
        },
        async getPorget() {
            const res = await this.$axios({
                url: "/api/orderProcess/getOrderProcessStateParam",
                method: "post"
            });

            if (res.code === 2000) {
                this.products = res.data.productListAll || []
            } else if (res.code !== 1003)
                throw res
        },

        clearSP_trim() {
            this.SP_.searchType = 1,
                this.SP_.searchTimeType = 1
        },

        async fetchData(noloading) {
            // if (!this.SP_.productName) {
            //     this.countMap = {
            //         fine: false
            //     }
            //     this.tableData = [];

            //     this.emptyText = '请输入产品名称进行搜索'

            //     return;
            // }
            console.log(this.SP_);
            let loading;
            if (noloading !== true) loading = this.$loading();

            try {
                // console.log({ ...this.extractParams() });

                // console.log();
                let extractParams = { ...this.extractParams() }


                this.fetchOrderSummary();
                // if (!this.enumIsReady) {
                //     await this.fetchEnum();
                // }

                let _data = {
                    pageSize: extractParams.pageSize,
                    pageNum: extractParams.pageNum,

                    searchProductId: this.SP_.productName || '',
                    searchType: this.SP_.searchType,
                    searchValue: this.SP_.searchValue,
                    searchTimeType: this.SP_.searchTimeType,
                    searchStartTime: this.SP_.searchStartTime,
                    searchEndTime: this.SP_.searchEndTime,
                    searchRegin: this.SP_.searchRegin.join(),
                    searchDept: this.SP_.searchDept.join(),
                    searchMoneyType: this.SP_.searchMoneyType.join(),
                    searchMoneyChildType: this.SP_.searchMoneyChildType.join(),
                    searchCheckState: this.SP_.searchCheckState.join(),
                    searchDealStatus: (this.SP_.searchDealStatus.length == 0 || this.SP_.searchDealStatus.length == 2) ? 0 : this.SP_.searchDealStatus.join(),
                }


                const res = await this.$axios({
                    // url: "/api/order/selectAllOutOrderList",
                    url: "/api/orderProcess/queryDueOrderProcessList",
                    method: "post",
                    data: _data
                    // data: {
                    //     search_value: '',
                    //     pageSize: extractParams.pageSize,
                    //     pageNum: extractParams.pageNum,
                    // }

                });

                if (res.code === 2000) {
                    this.total = res.data.count;
                    const list = res.data.dueOrderProcessList.map(it => {
                        it.createTimeName = it.createTimeName?.replace(" ", "<br>")
                        it.queueTimeName = it.queueTimeName?.replace(" ", "<br>")
                        if (!(it.state == 1 || it.state == 2)) it.sequence = "";
                        const _actionList = [...actionList];
                        it.actionList = _actionList;
                        // it.actionList = [
                        //     {
                        //         label: "维护",
                        //         name: "save",
                        //         banned: false
                        //     },
                        //     {
                        //         label: "详情",
                        //         name: "detail",
                        //         banned: false
                        //     },

                        // ];

                        // if (it.state == 3) {
                        //     const deleteActionIndex = it.actionList.findIndex(({ name }) => name == "delete");
                        //     if (deleteActionIndex) it.actionList.splice(deleteActionIndex, 1)
                        // }
                        return it;
                    });
                    this.tableData = list;
                    if (this.tableData.length == 0) {
                        this.emptyText = '暂无数据'
                    }
                    this.searchHrefType = "";

                    loading?.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading?.close();
            }
        },
        async fetchOrderSummary() {
            const { searchType, searchValue } = this.SP_;
            // if (searchType != 2 || !searchValue) return;
            if (!this.SP_.productName) {
                this.countMap = {
                    fine: false
                }
                return;
            }
            try {
                const res = await this.$axios({
                    url: "/api/orderProcess/queryTotalInfo",
                    method: "post",
                    data: {
                        searchProductId: this.SP_.productName
                    }
                });

                if (res.code === 2000) {
                    if (res.data.map) this.countMap = {
                        fine: true,
                        ...res.data.map
                    };

                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("业绩统计失败", reason)
            }
        },
        async fetchEnum() {
            const res = await this.$axios({
                // orderProcess/getOrderProcessStateParam
                url: "/api/orderProcess/getOrderProcessStateParam",
                method: "post"
            });

            if (res.code === 2000) {
                for (let key in res.data) {
                    this[key] = res.data[key];
                }
                this.enumIsReady = true;

                let _OrderProcessStateEnum = []
                this.OrderProcessStateEnum.forEach(element => {
                    if (element.value == 0 || element.value == 8 || element.value == 9 || element.value == 1 || element.value == 2) {
                        _OrderProcessStateEnum.push(element)
                    }
                });
                this.OrderProcessStateEnum = _OrderProcessStateEnum
                // 详情等页面回到列表页时 回显 部门
                if (this.SP_.searchRegin.length == 1) {
                    this.fetchDepartmentEnum(this.SP_.searchRegin[0]);
                }
            } else if (res.code !== 1003)
                throw res
        },

        // 点击高级搜索
        handleGJBtn() {
            if (this.showAdvanceSearch)
                this.closeAdvanceSearch()
            else this.handleAdvancedSearchBtn()
        },

        handleSearchReginChange(e) {
            this.SP_.searchDept = [];
            this.departmentEnum = [];
            if (e.length == 1) this.fetchDepartmentEnum(e[0]);
        },
        async fetchDepartmentEnum(pid) {
            console.log(123);
            try {
                const res = await this.$axios({
                    url: "/api/employee/queryOrgByPId",
                    method: "post",
                    data: { pid }
                });

                if (res.code === 2000) {
                    this.departmentEnum = res.data.regionList;
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
            }
        },

        handleSearchMoneyTypeChange(e) {
            this.SP_.searchMoneyChildType = [];
        },

        // 点击导出订单
        async handleExportBtn(flag) {
            if (!this.SP_.searchStartTime && !this.SP_.searchEndTime)
                return this.$message({
                    message: "请选择时间范围",
                    type: "warning"
                });

            let loadingRef,
                // url = flag === 1 ? "/api/order/downOrderPay" :
                //     "/api/orderProcess/downOrderProcess";
                url = "/api/orderProcess/downloadDueOrderProcess";
            // 
            try {
                await this.$confirm("确定导出吗？", "系统提示")
                loadingRef = this.$loading();

                const data = this.extractParams(true);
                data.searchProductId = this.SP_.productName
                const res = await this.$axios({
                    method: "post", url, data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },

        // 点击导出缴费
        handleExportPaymentsBtn() {
            // this.showExportPayments = true
            this.handleExportBtn(1)
        },

        // 点击: 订单状态说明
        handleExplainBtn() {
            this.showExplain = true
        },

        // 点击 "新增菜单"
        handleAddBtn() {
            this.$router.push({
                path: "/core/eoi/add",
                query: { sys_id: this.SP_.search_sys }
            });
        },

        // 点击 表格行的操作按钮
        handleAction(action, scope) {
            const type = action.name;
            const data = scope.row;
            switch (type) {
                case "save":
                    this.$router.push({
                        path: `/core/eoi/edit?id=${data.orderId || 5}`,
                        query: { sys_id: data.sys_id }
                    });
                    break;

                case "detail":
                    this.$router.push({
                        path: `/core/eoi/detail/${data.orderId || 5}`
                    });
                    break;

                case "delete":
                    this.handleBulkDelete([data])
                    break;

                default:
                    console.log("有没有操作处理程序");
                    break;
            }
        },

        // 点击: 删除
        async handleBulkDelete(list) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderProcess/deleteOrderProcess",
                    method: "post",
                    data: {
                        orderProcessId: list.map(
                            it => it.id).join()
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        }
    }
};