import { nanoid } from "nanoid";

export const today = (function () {
    let today = new Date(),
        Y = today.getFullYear(),
        M = today.getMonth() + 1,
        D = today.getDate();
    M = M < 10 ? `0${M}` : M;
    D = D < 10 ? `0${D}` : D;

    return `${Y}-${M}-${D}`;
})();

export const searchTypeEnum = [
    { name: "客户编号", value: 1 },
    { name: "签约销售", value: 2 }
]

export const dateTypeEnum = [
    { name: "签约日期", value: 1 },
    // { name: "进入排队日期", value: 2 }
]

export const payStateEnum = [
    { name: "全部", value: "" },
    { name: "未回款", value: 1 },
    { name: "部分回款", value: 2 },
    { name: "全款齐", value: 3 }
]

export const fileStateEnum = [
    { name: "全部", value: "" },
    { name: "已齐全", value: 1 },
    { name: "未齐全", value: 2 },
]

export const expireStateEnum = [
    { name: "全部", value: "" },
    { name: "未回款", value: 1 },
    { name: "部分回款", value: 2 },
    { name: "全款齐", value: 3 },
]

export const exportTypeEnum = [
    { name: "缴费日期", value: 1 },
    { name: "签约日期", value: 2 }
]

// 表格项（行）的操作菜单
export const actionList = [
    {
        label: "维护",
        name: "save",
        banned: false
    },
    {
        label: "详情",
        name: "detail",
        banned: false
    },
    // {
    //     label: "删除",
    //     name: "delete",
    //     banned: false
    // },
    // {
    //     label: "导出EOI报表",
    //     name: "export",
    //     banned: false
    // },
];

// 函数创建一个空的菜单数据
export function initialPB() {
    return {
        nanoid: nanoid(),
        name: "",
        sysId: "",
        roleId: "",
        busiDir: "",
        order: 99,
        level: 99,
        content: "",
    };
}